<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search affiliates
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by code or steamid"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        item-key="_id"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading"
        @pagination="onPagination"
      >
        <template #item.code="{ item, value }">
          <!-- TODO super dirty move to comp -->
          <div class="font-weight-semibold grey100--text">
            <fai
              v-if="item.overrides && item.overrides.reward"
              title="has reward" class="green400--text"
              :icon="['fad', 'gift-card']"
            />
            {{ value }}
          </div>
          <div class="text-caption text--secondary lh-1" v-text="item.userId" />
        </template>

        <template #item.tier="{ value, item }">
          <h6 class="text-body-2 blue200--text lh-1-2">
            Tier {{ value.tier }}
          </h6>
          <h6 class="text-caption text--secondary lh-1-2">
            <span
              v-if="item.overrides && item.overrides.kickback"
              class="green400--text"
            >{{ item.overrides.kickback * 100 | toLocale }}</span>
            <span v-else>{{ value.kickback * 100 | toLocale }}</span>
            <span>% kickback</span>
          </h6>
        </template>

        <template #item.totalDeposited="{ value }">
          <scrap :amount="value" />
        </template>

        <template #item.totalEarned="{ value, item }">
          <scrap :amount="value" />
          <h5 class="text-caption blue200--text">
            Bal: {{ item.balance | toScrap }}
          </h5>
        </template>

        <template #item.users="{ value }">
          <h6 class="text-caption grey100--text">
            {{ value.length }} users
          </h6>
        </template>

        <template #item.createdAt="{ value }">
          <v-tooltip v-if="value" top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
          <p v-else class="mb-0 text--disabled lh-1-4 text-caption">
            &mdash;
          </p>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'pencil']"
            class="primary500--text link"
            @click="onExpandItem(item)"
          />
        </template>

        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <div class="grey700 pa-4 mb-4">
              <h4 class="text-uppercase primary500--text mb-2">
                Set overrides
              </h4>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.number="form.overrides.kickback"
                    solo dense flat
                    placeholder="Kickback (0-1)"
                    hide-details
                    background-color="grey600"
                    class="rounded-0 grey100--text text-caption"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-currency-field
                    v-model="form.overrides.reward"
                    :options="{ allowNegative: false }"
                    placeholder="Scrap reward for first claim"
                    class="rounded-0 grey100--text text-caption"
                    solo hide-details dense
                    flat background-color="grey600"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    class="rounded-0 font-weight-bold"
                    color="blue700 blue300--text" small
                    :ripple="false"
                    :loading="loading"
                    @click="onSetOverrides(item.id, form.overrides)"
                  >
                    <fai :icon="['fas', 'check']" class="mr-2" />
                    Update overrides
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <v-expansion-panels accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header color="grey600">
                  Full affiliate object
                </v-expansion-panel-header>
                <v-expansion-panel-content color="grey600">
                  <pre v-text="item" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </template>
      </v-data-table>
    </section>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      total: 0,
      search: '',
      status: ['pending', 'complete', 'listed'],
      expanded: [],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        {
          text: 'Code & User', value: 'code', align: 'start', sortable: false,
        },
        {
          text: 'Tier', value: 'tier', align: 'start', sortable: false,
        },
        {
          text: 'Active users', value: 'users', align: 'start',
        },
        { text: 'Deposited', value: 'totalDeposited', align: 'end' },
        { text: 'Earnings', value: 'totalEarned', align: 'end' },
        { text: 'Created', value: 'createdAt', align: 'end' },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      form: {
        overrides: {
          kickback: null,
          reward: null,
        },
      },
      entries: [],
    }
  },
  computed: {
    ...mapGetters({
      conga: 'conga/conga',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
  },
  methods: {
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    async fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.affiliates.list', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onSetOverrides(id, overrides) {
      this.loading = true

      const form = {}
      if (overrides.kickback) form.kickback = overrides.kickback
      if (overrides.reward) form.reward = overrides.reward

      try {
        await this.$socket.request('admin.affiliates.setOverrides', { id, overrides: form })
        this.$toast.success('Overrides updated')
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item._id === expandedItem?._id) {
        this.expanded = []
        this.form.overrides = {
          kickback: null,
          reward: null,
        }
      } else {
        this.expanded = [item]
        this.form.overrides = {
          kickback: item.overrides?.kickback ?? null,
          reward: item.overrides?.reward ?? null,
        }
      }
    },
    onPagination() {
      this.expanded = []
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

pre {
  overflow-x: auto;
  max-height: 250px;
  white-space: pre-wrap;
  word-break: break-all;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
