var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"mb-6"},[_c('div',{staticClass:"heading d-flex align-center mb-3"},[_c('h3',{staticClass:"title"},[_vm._v(" Search affiliates ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"rounded-0 text-caption mr-2",attrs:{"solo":"","dense":"","hide-details":"","flat":"","placeholder":"Search by code or steamid","background-color":"grey800"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"unwidth unheight align-self-stretch",attrs:{"color":"grey300","tile":"","small":"","ripple":false,"loading":_vm.loading},on:{"click":_vm.fetchApiData}},[_c('fai',{staticClass:"grey100--text",attrs:{"icon":['fas','sync']}})],1)],1),_c('v-data-table',{staticClass:"rounded-0 data-table",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 25] },"server-items-length":_vm.total,"expanded":_vm.expanded,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"font-weight-semibold grey100--text"},[(item.overrides && item.overrides.reward)?_c('fai',{staticClass:"green400--text",attrs:{"title":"has reward","icon":['fad', 'gift-card']}}):_vm._e(),_vm._v(" "+_vm._s(value)+" ")],1),_c('div',{staticClass:"text-caption text--secondary lh-1",domProps:{"textContent":_vm._s(item.userId)}})]}},{key:"item.tier",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('h6',{staticClass:"text-body-2 blue200--text lh-1-2"},[_vm._v(" Tier "+_vm._s(value.tier)+" ")]),_c('h6',{staticClass:"text-caption text--secondary lh-1-2"},[(item.overrides && item.overrides.kickback)?_c('span',{staticClass:"green400--text"},[_vm._v(_vm._s(_vm._f("toLocale")(item.overrides.kickback * 100)))]):_c('span',[_vm._v(_vm._s(_vm._f("toLocale")(value.kickback * 100)))]),_c('span',[_vm._v("% kickback")])])]}},{key:"item.totalDeposited",fn:function(ref){
var value = ref.value;
return [_c('scrap',{attrs:{"amount":value}})]}},{key:"item.totalEarned",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('scrap',{attrs:{"amount":value}}),_c('h5',{staticClass:"text-caption blue200--text"},[_vm._v(" Bal: "+_vm._s(_vm._f("toScrap")(item.balance))+" ")])]}},{key:"item.users",fn:function(ref){
var value = ref.value;
return [_c('h6',{staticClass:"text-caption grey100--text"},[_vm._v(" "+_vm._s(value.length)+" users ")])]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(new Date(value).getTime(),'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(new Date(value).getTime(),'YYYY-MM-DD HH:mm:ss')))])]):_c('p',{staticClass:"mb-0 text--disabled lh-1-4 text-caption"},[_vm._v(" — ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('fai',{staticClass:"primary500--text link",attrs:{"icon":['fad', 'pencil']},on:{"click":function($event){return _vm.onExpandItem(item)}}})]}},{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"grey800 py-3 data-detail",attrs:{"colspan":head.length}},[_c('div',{staticClass:"grey700 pa-4 mb-4"},[_c('h4',{staticClass:"text-uppercase primary500--text mb-2"},[_vm._v(" Set overrides ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-0 grey100--text text-caption",attrs:{"solo":"","dense":"","flat":"","placeholder":"Kickback (0-1)","hide-details":"","background-color":"grey600"},model:{value:(_vm.form.overrides.kickback),callback:function ($$v) {_vm.$set(_vm.form.overrides, "kickback", _vm._n($$v))},expression:"form.overrides.kickback"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-currency-field',{staticClass:"rounded-0 grey100--text text-caption",attrs:{"options":{ allowNegative: false },"placeholder":"Scrap reward for first claim","solo":"","hide-details":"","dense":"","flat":"","background-color":"grey600"},model:{value:(_vm.form.overrides.reward),callback:function ($$v) {_vm.$set(_vm.form.overrides, "reward", $$v)},expression:"form.overrides.reward"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"rounded-0 font-weight-bold",attrs:{"color":"blue700 blue300--text","small":"","ripple":false,"loading":_vm.loading},on:{"click":function($event){return _vm.onSetOverrides(item.id, _vm.form.overrides)}}},[_c('fai',{staticClass:"mr-2",attrs:{"icon":['fas', 'check']}}),_vm._v(" Update overrides ")],1)],1)],1)],1),_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey600"}},[_vm._v(" Full affiliate object ")]),_c('v-expansion-panel-content',{attrs:{"color":"grey600"}},[_c('pre',{domProps:{"textContent":_vm._s(item)}})])],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }